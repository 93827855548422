'use strict';

angular.module('enervexSalesappApp').factory('Property', function ($resource) {
	return $resource('/api/properties/:id/:configs', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		},
		configs: {
			method: 'GET',
			isArray: false,
			params: {
				configs: 'configs'
			}
		}
	});
});
